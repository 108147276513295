.resources-page{
    margin-top: 110px;
    justify-content: center;
    width: 90%;


}

.resources-header{
    text-align: center;
    padding-bottom: 30px;
    color: #F10472;



}