.signup-container {
    display: flex;
    justify-content: space-between;
    min-height: 100vh;
    border-radius: 20px;
    overflow: hidden;
    background-color: #faf5ef;
}

.form-section {
    flex: 1;
    background-color: #faf5ef;
    padding: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.promo-section {
    flex: 1;
    background: linear-gradient(135deg, #ffe299c2, #e4b9ff, #ff3777e7);
    color: #333;
    padding: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    border-top-left-radius: 25vh;
    border-bottom-right-radius: 25vh;
    border-top-right-radius: 2vh;
    border-bottom-left-radius: 2vh;
    position: relative;
    overflow: hidden;
}

.signup-page h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: #1f1f1f;
    position: relative;
    margin-left: 22.5vh;
}

.DescriptionOnSignup {
    margin-bottom: 20px;
    text-align: center;
    font-size: 1.25rem;
}

h2 {
    margin-left: 3.5rem;
}

.google-signup {
    background: white;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 10px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    cursor: pointer;
    margin-bottom: 15px;
    margin-left: 55px;
}

.google-signup:hover {
    background-color: #ffed85;

}

.or-divider {
    display: flex; /* Use flexbox for alignment */
    align-items: center; /* Center vertically */
    margin: 20px 0; /* Adjust vertical spacing as needed */
    color: #999;
}

.or-divider::before,
.or-divider::after {
    content: '';
    flex: 1; /* Allow lines to grow and fill space */
    height: 1px; /* Line thickness */
    background-color: #ddd; /* Line color */
}

/* Adjust the width of the lines */
.or-divider::before {
    width: 20%; /* Adjust this value to shorten the left line */
    margin-right: 20px; /* Space between line and text */
    margin-left: 60px;
}

.or-divider::after {
    width: 20%; /* Adjust this value to shorten the right line */
    margin-left: 20px; /* Space between line and text */
    margin-right: 50px;
}


label {
    display: block;
    margin-bottom: 5px;
    color: #333;
    font-weight: bold;
}

input {
    width: 80%;
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid #e5e5e57c;
    border-radius: 25px;
    font-size: 16px;
    position: relative;    
    background-color: #e5e5e57c;    
    margin-left: 55px;
    cursor: pointer;
}

.primary-button {
    background: #6b47dc;
    color: white;
    padding: 12px;
    border-radius: 15px;
    font-size: 16px;
    cursor: pointer;
    border: none;
   
    width: 80%;
    margin-left: 55px;
}

.primary-button:hover{
    Transition: color #03fff2 10s !important;
    background-color: #d103ff;
}

.terms {
    font-size: 12px;
    color: #999;
    margin-top: 15px;
}

@media (max-width: 768px) {
    .signup-container {
        flex-direction: column;
        align-items: center;
    }
    .form-section, .promo-section {
        width: 100%;
        padding: 30px;
    }
    .promo-section {
        order: -1;
    }
}

/* Hide the navigation bar when the .signup-page class is applied to the body */
.signup-page nav {
    display: none !important;
}

#name, #email, #password {
    margin-left: 0;
}

.back-link-container {
    position: relative; /* Positioning context for the absolute button */
}

.back-link {
    color: #999;
    position: absolute; /* Position the button absolutely */
    top: -70px; /* Move the button 20px upwards */
    left: 0; /* Align to the left */
    display: inline-block; /* Ensure it behaves like a block element */
}

.back-link:hover {
    color: black;
}



.logo-text {
    max-width: none; /* Remove the size constraint */
    margin-bottom: 20px; /* Space between logo and heading */
    font-size: 60px !important; /* Restore original size */
    margin-left: 10rem;
}

.logo-container {
    text-align: left; /* Center the logo */
    margin-bottom: 20px; /* Space below the logo */
}

.form-group {
    padding: 7px;
    margin-left: 60px;
    margin-right: -45px;
}

.star-icon {
    width: 54px !important; /* Adjust width */
    height: 54px !important; /* Adjust height */
    margin-left: 5px; /* Space between logo text and icon */
}




/* Bottom Marquee */
.marquee-bottom {
    position: absolute; /* Position the marquee absolutely within the promo section */
    white-space: nowrap; /* Prevent line breaks */
    width: 100%; /* Full width of the promo section */
    bottom: 10%; /* Position at the bottom */
}

.marquee-content-bottom {
    display: inline-block;
    animation: float-right 90s linear infinite; /* Slide to the right */
    font-size: 2rem; /* Adjust font size as needed */
    font-weight: 600; /* Make the text bold */
    color: #000; /* Text color */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Keyframes for left sliding */
@keyframes float-left {
    0% {
        transform: translateX(0%); /* Start from the right edge */
    }
    100% {
        transform: translateX(-100%); /* Move to the left edge */
    }
}

/* Keyframes for right sliding */
@keyframes float-right {
    0% {
        transform: translateX(-100%); /* Start from the left edge */
    }
    100% {
        transform: translateX(0%); /* Move to the right edge */
    }
}
