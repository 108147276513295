.contactContainer {
    width: 100vw;
    height: 95vh;
    justify-content: center;
    align-items: center;
    display: flex;
}

.schemaContainer {
    display: flex;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(255, 42, 156, 0.516);
    overflow: hidden;
    width: 80%;
    max-width: 1000px;

    margin: 40px auto;
}
.left-column {
    flex: 1;
    padding: 40px;
    background-color: #fff9f3;
}
.right-column {
    flex: 1;
    background-color: #fff9f3;
    padding: 40px;
}
h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
}
h1 span {
    color: #ff6bb3;
}
p {
    color: #666;
    line-height: 1.6;
}
.contact-info {
    margin-top: 30px;
}
.social-section {
    margin-top: 30px;
}
.social-section h3 {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 15px;
}
.social-links {
    display: flex;
}
.social-links a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: #F10472; /* Your main pink color */
    border-radius: 50%;
    color: white;
    margin-right: 10px;
    text-decoration: none;
    font-size: 1.2em;
    transition: all 0.3s ease;
}

.social-links a:hover {
    background-color: white;
    color: #F10472; /* Your main pink color */
    box-shadow: 0 0 10px rgba(241, 4, 114, 0.5); /* Optional: adds a subtle glow effect */
}
form {
    display: flex;
    flex-direction: column;
}
input, textarea {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}
button {
    background-color: #8a6bff;
    color: white;
    border: none;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
}